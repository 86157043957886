import { Col, Row, Select, Form, Input } from "antd";
import {
	academicYears,
	semesters,
	studentYear,
} from "../../../helpers/constants";
import React from "react";
import { Programme } from "../../../interfaces";

interface StepOneProps {
	programmes: Programme[];
}

const RegistrationSettings: React.FC<StepOneProps> = ({ programmes }) => {
	const { Option } = Select;

	return (
		<>
			<Row gutter={10}>
				<Col span={12} md={8} sm={24} xs={24}>
					<Form.Item
						name="academic_year"
						label="Academic Year"
						rules={[
							{
								required: true,
								message: "Academic year is required!",
							},
						]}
					>
						<Select placeholder="Select the academic year..">
							{academicYears.map((aca) => (
								<Option value={aca.value} key={aca.value}>
									{aca.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={12} md={8} sm={24} xs={24}>
					<Form.Item
						name="academic_level"
						label="Academic Level"
						rules={[
							{
								required: true,
								message: "Academic Level is required!",
							},
						]}
					>
						<Select placeholder="Select the Academic Level...">
							{studentYear.map((level) => (
								<Option value={level.value} key={level.value}>
									{level.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={12} md={8} sm={24} xs={24}>
					<Form.Item
						name="semester"
						label="Semester"
						rules={[
							{
								required: true,
								message: "Semester is required!",
							},
						]}
					>
						<Select placeholder="Select the Semester...">
							{semesters.map((semester) => (
								<Option
									value={semester.value}
									key={semester.value}
								>
									{semester.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={10}>
				<Col span={12} md={12} sm={24} xs={24}>
					<Form.Item
						name="programme_id"
						label="Programme"
						rules={[
							{
								required: true,
								message: "Programme is required!",
							},
						]}
					>
						<Select placeholder="Select the programme.." allowClear>
							{programmes.map((programme) => (
								<Option value={programme.id} key={programme.id}>
									{programme.programme}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col span={12} md={12} sm={24} xs={24}>
					<Form.Item
						name="minimum_credit_hours"
						label="Minimum Credits Hours"
						rules={[
							{
								required: true,
								message: "'Minimum Credit Hours' is required!",
							},
						]}
					>
						<Input type="number" min={1} allowClear />
					</Form.Item>
				</Col>
			</Row>
		</>
	);
};

export default RegistrationSettings;
