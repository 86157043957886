import { action } from "typesafe-actions";
import { CourseRegistrationTypes } from "./types";
import { CourseRegistration, CourseRegistrationPayload } from "../../interfaces/CourseRegistration";

export const getCourseRegistrations = (payload: CourseRegistrationPayload) => {
	return action(CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS, payload);
};

export const getCourseRegistrationsSuccess = (courseRegistrations: CourseRegistration[]) => {
	return action(CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS_SUCCESS, courseRegistrations);
};

export const getCourseRegistrationsFailure = (error: any) => {
	return action(CourseRegistrationTypes.GET_ALL_COURSE_REGISTRATIONS_FAILURE, error);
};
