export const path = {
	home: "/",
	terms: "/terms",
	register: "/auth/register",
	login: "/auth/login",
	verify: "/auth/account-verification",
	resend: "/auth/resend-code",
	reset: "/auth/reset-password",
	school: "/school",
	programmes: "/programmes",
	students: "/students",
	letter: "/letter",
	fees: "/fees",
	profile: "/profile",
	transfers: "/transfers",
	clients: "/clients",
	trainings: "/trainings",
	academics: "/academics",
	courseRegistrationsSetup: "/course-registrations/setup",
	courseRegistrations: "/course-registrations",
};
