import { CourseRegistrationSetup } from "../../interfaces/CourseRegistrationSetup";

export enum CourseRegistrationSetupTypes {
	SUBMITTING = "@@courseRegSetup/SUBMITTING",
	CREATE_COURSE_REGISTRATION_SETUP = "@@courseRegSetup/CREATE_COURSE_REGISTRATION_SETUP",
	CREATE_COURSE_REGISTRATION_SETUP_SUCCESS = "@@courseRegSetup/CREATE_COURSE_REGISTRATION_SETUP_SUCCESS",
	CREATE_COURSE_REGISTRATION_SETUP_FAILURE = "@@courseRegSetup/CREATE_COURSE_REGISTRATION_SETUP_FAILURE",
	DELETE_REQUEST = "@@courseRegSetup/DELETE_REQUEST",
	DELETE_SUCCESS = "@@courseRegSetup/DELETE_SUCCESS",
	DELETE_FAILURE = "@@courseRegSetup/DELETE_FAILURE",
	GET_ALL_COURSE_REGISTRATION_SETUPS = "@@courseRegSetup/GET_ALL_COURSE_REGISTRATION_SETUPS",
	GET_ALL_COURSE_REGISTRATION_SETUPS_SUCCESS = "@@courseRegSetup/GET_ALL_COURSE_REGISTRATION_SETUPS_SUCCESS",
	GET_ALL_COURSE_REGISTRATION_SETUPS_FAILURE = "@@courseRegSetup/GET_ALL_COURSE_REGISTRATION_SETUPS_FAILURE",
	CLEAR_BOOLEAN_STATES = "@@courseRegSetup/CLEAR_BOOLEAN_STATES",
	ACTION_REQUEST = "@@courseRegSetup/ACTION_REQUEST",
	ACTION_SUCCESS = "@@courseRegSetup/ACTION_SUCCESS",
	ACTION_FAILURE = "@@courseRegSetup/ACTION_FAILURE",
	CLEAR_DATA = "@@courseRegSetup/CLEAR_DATA",
	NOTIFY_STUDENTS = "@@courseRegSetup/NOTIFY_STUDENTS",
	NOTIFY_STUDENTS_SUCCESS = "@@courseRegSetup/NOTIFY_STUDENTS_SUCCESS",
	NOTIFY_STUDENTS_FAILURE = "@@courseRegSetup/NOTIFY_STUDENTS_FAILURE",
}

export type CourseRegistrationSetupState = {
	readonly isSubmitting: boolean;
	readonly courseRegistrationSetup: CourseRegistrationSetup | undefined;
	readonly courseRegistrationSetups: CourseRegistrationSetup[];
	readonly error: any;
	readonly success: boolean;
	readonly failure: boolean;
	readonly page: string;
	readonly isDeleting: boolean;
	readonly deleteSuccess: boolean;
	readonly performAction: boolean;
	readonly actionSuccess: boolean;
	readonly actionFailure: boolean;
	readonly loading: boolean;
	readonly exporting: boolean;
	readonly exportSuccess: boolean;
	readonly exportFailure: boolean;
	readonly transferCount: number;
};
