import { Reducer } from "redux";
import { CourseRegistrationSetupState, CourseRegistrationSetupTypes } from "./types";
import { AuthActionTypes } from "../auth";
import { constants } from "../../helpers/constants";

export const initialState: CourseRegistrationSetupState = {
	actionFailure: false,
	actionSuccess: false,
	deleteSuccess: false,
	error: undefined,
	failure: false,
	isDeleting: false,
	isSubmitting: false,
	page: "",
	performAction: false,
	success: false,
	courseRegistrationSetup: undefined,
	loading: false,
	exporting: false,
	exportFailure: false,
	exportSuccess: false,
	transferCount: 0,
	courseRegistrationSetups: [],
};

const reducer: Reducer<CourseRegistrationSetupState> = (state = initialState, action) => {
	switch (action.type) {
		case CourseRegistrationSetupTypes.CREATE_COURSE_REGISTRATION_SETUP:
			return {
				...state,
				isSubmitting: true,
				error: null,
				success: false,
				failure: false,
			};
		case CourseRegistrationSetupTypes.CREATE_COURSE_REGISTRATION_SETUP_SUCCESS:
			return {
				...state,
				isSubmitting: false,
				success: true,
				failure: false,
				page: constants.courseRegistrationSetups,
				courseRegistrationSetups: [action.payload, ...state.courseRegistrationSetups],
			};
		case CourseRegistrationSetupTypes.CREATE_COURSE_REGISTRATION_SETUP_FAILURE:
			return {
				...state,
				isSubmitting: false,
				success: false,
				failure: true,
				error: action.payload,
				page: constants.courseRegistrationSetups,
			};

		case CourseRegistrationSetupTypes.GET_ALL_COURSE_REGISTRATION_SETUPS:
			return {
				...state,
				isSubmitting: true,
				error: null,
				success: false,
				failure: false,
			};
		case CourseRegistrationSetupTypes.GET_ALL_COURSE_REGISTRATION_SETUPS_SUCCESS:
			return {
				...state,
				isSubmitting: false,
				success: true,
				failure: false,
				page: constants.courseRegistrationSetups,
				courseRegistrationSetups: action.payload,
			};
		case CourseRegistrationSetupTypes.GET_ALL_COURSE_REGISTRATION_SETUPS_FAILURE:
			return {
				...state,
				isSubmitting: false,
				success: false,
				failure: true,
				error: action.payload,
				page: constants.courseRegistrationSetups,
			};

		case CourseRegistrationSetupTypes.NOTIFY_STUDENTS:
			return {
				...state,
				isSubmitting: true,
				error: null,
				success: false,
				failure: false,
			};
		case CourseRegistrationSetupTypes.NOTIFY_STUDENTS_SUCCESS:
			return {
				...state,
				isSubmitting: false,
				error: null,
				success: true,
				failure: false,
			};
		case CourseRegistrationSetupTypes.NOTIFY_STUDENTS_FAILURE:
			return {
				...state,
				isSubmitting: false,
				success: false,
				failure: true,
				error: action.payload,
			};

		case CourseRegistrationSetupTypes.DELETE_REQUEST:
			return {
				...state,
				isDeleting: true,
				error: null,
				deleteSuccess: false,
			};
		case CourseRegistrationSetupTypes.DELETE_SUCCESS:
			return {
				...state,
				isDeleting: false,
				transfers: state.courseRegistrationSetups.filter((transfer) => transfer.id !== action.payload),
				deleteSuccess: true,
				page: constants.courseRegistrationSetups,
			};
		case CourseRegistrationSetupTypes.DELETE_FAILURE:
			return {
				...state,
				isDeleting: false,
				error: action.payload,
				deleteSuccess: false,
				page: constants.courseRegistrationSetups,
			};
		case CourseRegistrationSetupTypes.ACTION_REQUEST:
			return {
				...state,
				performAction: true,
				error: undefined,
			};
		case CourseRegistrationSetupTypes.ACTION_SUCCESS:
			return {
				...state,
				performAction: false,
				actionSuccess: true,
				actionFailure: false,
				page: constants.courseRegistrationSetups,
			};
		case CourseRegistrationSetupTypes.ACTION_FAILURE:
			return {
				...state,
				performAction: false,
				actionFailure: true,
				actionSuccess: false,
				error: action.payload,
				page: constants.courseRegistrationSetups,
			};
		case CourseRegistrationSetupTypes.CLEAR_BOOLEAN_STATES:
			return {
				...state,
				success: false,
				failure: false,
				deleteSuccess: false,
				isSubmitting: false,
				isDeleting: false,
				error: undefined,
				performAction: false,
				actionFailure: false,
				actionSuccess: false,
				exporting: false,
				exportFailure: false,
				exportSuccess: false,
			};
		case CourseRegistrationSetupTypes.CLEAR_DATA:
			return {
				...state,
				courseRegistrationSetups: [],
			};
		case AuthActionTypes.DESTROY_STATES:
			return initialState;
		default:
			return state;
	}
};

export { reducer as courseRegistrationSetupReducer };
