import React from 'react'
import {GPAs} from '../../interfaces'
import moment from 'moment'
import {Button, Col, Dropdown, Menu, Popconfirm, Row, Space, Table, Tag} from 'antd'
import {CloseSquareOutlined, DeleteOutlined, DownOutlined, ExclamationCircleOutlined, NotificationOutlined} from '@ant-design/icons'

interface Props {
    gpas: GPAs[]
    deleteGPA(gpa: GPAs): void
    onPublishGPA(gpa: GPAs): void
    onUnPublishGPA(gpa: GPAs): void
    publishingGPA: boolean
    unPublishingGPA: boolean
    isDeleting: boolean
    id: number
    loading: boolean
}

const GPAsList: React.FC<Props> = (props) => {
    const {
        gpas,
        deleteGPA,
        onPublishGPA,
        onUnPublishGPA,
        publishingGPA,
        unPublishingGPA,
        isDeleting,
        id,
        loading
    } = props

    const columns: any[] = [
        {
            title: 'Index Number',
            dataIndex: 'index_number',
            align: 'left',
            key: 'index_number',
            sorter: (a: GPAs, b: GPAs) =>
                a.index_number.length - b.index_number.length
        },
        {
            title: 'Surname',
            dataIndex: 'surname',
            align: 'left',
            key: 'surname'
        },
        {
            title: 'Other Names',
            dataIndex: 'other_names',
            align: 'left',
            key: 'other_names'
        },
        {
            title: 'TCU',
            dataIndex: 'tcu',
            align: 'left',
            key: 'tcu',
            sorter: (a: GPAs, b: GPAs) => a.tcu.length - b.tcu.length
        },
        {
            title: 'TGP',
            dataIndex: 'tgp',
            align: 'left',
            key: 'tgp',
            sorter: (a: GPAs, b: GPAs) =>
                a.tgp.length - b.tgp.length
        },
        {
            title: 'GPA',
            dataIndex: 'gpa',
            align: 'left',
            key: 'gpa',
            sorter: (a: GPAs, b: GPAs) =>
                a.gpa.length - b.gpa.length
        },
        {
            title: 'CGPA',
            dataIndex: 'cgpa',
            align: 'left',
            key: 'cgpa',
            sorter: (a: GPAs, b: GPAs) =>
                a.cgpa.length - b.cgpa.length
        },
        {
            title: 'Semester',
            dataIndex: 'semester',
            align: 'left',
            key: 'semester',
            sorter: (a: GPAs, b: GPAs) =>
                a.cgpa.length - b.cgpa.length
        },
        {
            title: 'Year',
            dataIndex: 'year',
            align: 'left',
            key: 'year',
            sorter: (a: GPAs, b: GPAs) =>
                a.cgpa.length - b.cgpa.length
        },
        {
            title: 'Academic Year',
            dataIndex: 'academic_year',
            align: 'left',
            key: 'academic_year',
            sorter: (a: GPAs, b: GPAs) =>
                a.cgpa.length - b.cgpa.length
        },
        {
            title: 'Status',
            dataIndex: 'published',
            align: 'center',
            key: 'published',
            render: (published: number) => {
                let color: string, meaning: string

                switch (published) {
                    case 1:
                        color = '#41b883'
                        meaning = 'PUBLISHED'
                        break
                    case 0:
                        color = '#868686'
                        meaning = 'NOT PUBLISHED'
                        break
                    default:
                        color = '#868686'
                        meaning = 'NOT PUBLISHED'
                        break
                }
                return (
                    <Tag color={color} style={{borderRadius: '10px', fontSize: '12px'}}>
                        {meaning}
                    </Tag>
                )
            }
        },
        {
            title: 'Date Created',
            dataIndex: 'created_at',
            align: 'left',
            key: 'created_at'
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (gpa: GPAs) => {
                const actions = (
                    <Menu>
                        {gpa.published === 0 ? (
                            <Menu.Item
                                key="2"
                                onClick={() => onPublishGPA(gpa)}
                                icon={<NotificationOutlined/>}
                                title="Publish for students who do not owe fees">
                                Publish
                            </Menu.Item>
                        ) : <Menu.Item
                            key="3"
                            onClick={() => onUnPublishGPA(gpa)}
                            icon={<CloseSquareOutlined/>}
                            title="Revoke GPA publication">
                            Revoke Publication
                        </Menu.Item>}
                    </Menu>
                )
                return (
                    <Space>
                        <Dropdown overlay={actions}>
                            <Button
                                type="primary"
                                style={{fontSize: '12px'}}
                                loading={((publishingGPA || unPublishingGPA) && (gpa.id === id))}>
                                Actions <DownOutlined/>
                            </Button>
                        </Dropdown>
                        <Popconfirm
                            placement="topLeft"
                            title="Are you sure you want to delete GPA?"
                            onConfirm={() => deleteGPA(gpa)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined/>}
                                style={{fontSize: '12px'}}
                                loading={isDeleting && gpa.id === id}
                            >
                                Delete
                            </Button>
                        </Popconfirm>
                    </Space>
                )
            }
        }
    ]

    let data: any[] = []

    gpas.length &&
    gpas.map((gpa) => {
        let surname = () => {
            return gpa.student !== null ? gpa.student?.surname :
                <Tag icon={<ExclamationCircleOutlined />} color="error">
                    Not Available
                </Tag>
        };

        let otherNames = () => {
            return gpa.student !== null ? gpa.student?.other_names :
                <Tag icon={<ExclamationCircleOutlined />} color="error">
                    Not Available
                </Tag>
        };

        data.push({
            id: gpa.id,
            key: gpa.id,
            index_number: gpa.index_number,
            surname: surname(),
            other_names: otherNames(),
            tcu: gpa.tcu,
            tgp: gpa.tgp,
            gpa: gpa.gpa,
            cgpa: gpa.cgpa,
            semester: gpa.semester,
            year: gpa.year,
            academic_year: gpa.academic_year,
            published: gpa.published,
            created_at: moment(gpa.created_at, 'YYYY-MM-DD HH:mm:ss').format(
                'MMMM D, YYYY'
            )
        })
        return data
    })

    return (
        <Row gutter={20}>
            <Col span={24}>
                <div>
                    <Table
                        dataSource={data}
                        columns={columns}
                        bordered
                        pagination={{
                            hideOnSinglePage: true,
                            total: data.length,
                            showTotal: (total, range) => {
                                return `Showing ${range[0]} - ${range[1]} of ${total} results`
                            }
                        }}
                        className="students-table"
                        scroll={{x: true}}
                        loading={loading}
                    />
                </div>
            </Col>
        </Row>
    )
}

export default GPAsList
