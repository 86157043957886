import React from "react";
import { semesters } from "../../helpers/constants";
import { Button, Col, Dropdown, Menu, Space, Table, Tag } from "antd";
import moment from "moment";
import { CourseRegistration, RegisteredCourse } from "../../interfaces/CourseRegistration";
import { EyeOutlined, EditOutlined, DeleteOutlined, DownOutlined, MessageFilled, PhoneFilled } from "@ant-design/icons";

interface Props {
	courseRegistrations: CourseRegistration[];
	loading: boolean;
}

const menu = (
	<Menu>
		<Menu.Item key={1} icon={<EyeOutlined />}>
			View Courses
		</Menu.Item>
		<Menu.Item key={2} danger icon={<DeleteOutlined />}>
			Delete
		</Menu.Item>
	</Menu>
);

export const RegistrationsList: React.FC<Props> = ({ courseRegistrations, loading }) => {
	const columns: any[] = [
		{
			title: "Student ID / Index Number",
			dataIndex: "index_number",
			key: "index_number",
			sorter: (a: any, b: any) => a.id - b.id,
		},
		{
			title: "Student Name",
			dataIndex: "student_name",
			key: "student_name",
			sorter: (a: any, b: any) => a.id - b.id,
		},
		{
			title: "Programme Name",
			dataIndex: "programme_name",
			align: "left",
			key: "programme_name",
			sorter: (a: any, b: any) => a.programme_name - b.programme_name,
		},
		{
			title: "Academic Year",
			dataIndex: "academic_year",
			align: "left",
			key: "academic_year",
			sorter: (a: any, b: any) => a.id - b.id,
		},
		{
			title: "Semester",
			dataIndex: "semester",
			align: "left",
			key: "semester",
			sorter: (a: any, b: any) => a.id - b.id,
			render: (semesterValue: any) => {
				return semesters.map((semester: any) => {
					return semesterValue == semester.value ? semester.label : "";
				});
			},
		},
		{
			title: "Course Registered",
			dataIndex: "course_registration_details_count",
			align: "right",
			key: "course_registration_details_count",
			sorter: (a: any, b: any) => a.id - b.id,
			render: (course_registration_details_count: number) => (
				<Tag color="volcano">{course_registration_details_count}</Tag>
			),
		},
		{
			title: "Total Credit Hours",
			dataIndex: "total_credit_hours",
			align: "right",
			key: "total_credit_hours",
			sorter: (a: any, b: any) => a.id - b.id,
			render: (total_credit_hours: number) => <Tag color="green">{total_credit_hours}</Tag>,
		},
		{
			title: "Date Registered",
			dataIndex: "created_at",
			align: "left",
			key: "created_at",
			render: (date_registered: string) => moment(date_registered).format("dddd DD MMM, YYYY @ HH:mm:ss"),
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (_: any) => (
				<Space>
					<Dropdown overlay={menu}>
						<Button type="dashed" icon={<DownOutlined />} onClick={() => {}}>
							Actions
						</Button>
					</Dropdown>
				</Space>
			),
		},
	];

	const registrations: CourseRegistration[] = [];
	let totalCreditHours = 0;

	courseRegistrations.map((registration: CourseRegistration) => {
		registration.course_registration_details.map((registeredCourse: RegisteredCourse) => {
			return (totalCreditHours += parseFloat(registeredCourse.course?.credit_hours as string));
		});

		registrations.push({
			academic_year: registration.academic_year,
			id: registration.id,
			school_id: registration.school_id,
			student_id: registration.student_id,
			programme_id: registration.programme_id,
			course_registration_definition_id: registration.course_registration_definition_id,
			semester: registration.semester,
			level: registration.level,
			uuid: registration.uuid,
			created_at: registration.created_at,
			course_registration_details: registration.course_registration_details,
			total_credit_hours: totalCreditHours,
			student_name: registration.student?.surname + " " + registration.student?.other_names,
			programme_name: registration.programme?.programme,
			course_registration_details_count: registration.course_registration_details_count,
			index_number: registration.student?.application_number,
		});
	});

	return (
		<Col span={24}>
			<div>
				<Table
					loading={loading}
					dataSource={registrations}
					columns={columns}
					bordered
					pagination={{
						hideOnSinglePage: true,
						total: registrations.length,
						showTotal: (total, range) => {
							return `Showing ${range[0]} - ${range[1]} of ${total} results`;
						},
					}}
					className="students-table"
					scroll={{ x: true }}
				/>
			</div>
		</Col>
	);
};
