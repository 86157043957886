import React from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Table,
  TableColumnProps,
  Tag,
  Anchor,
  Space,
  Popconfirm,
} from "antd";
import { OtherDocumentDownload, RemoveOtherDocument } from "../../interfaces";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeDocRequest } from "../../store/letter";
import { appSelector } from "../../helpers/appSelector";

interface Props {
  otherDocuments?: OtherDocumentDownload[];
  open: boolean;
  onOpenChange(): void;
}

export const DownloadOtherDocsModal = ({
  otherDocuments,
  open,
  onOpenChange,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { school } = appSelector((state) => state.schoolStore);
  const { isRemovingDoc } = appSelector((state) => state.letterStore);

  const handleRemoveDoc = (payload: RemoveOtherDocument) => {
    dispatch(removeDocRequest(payload));
  };

  const columns: TableColumnProps<OtherDocumentDownload>[] = [
    {
      title: "S/N",
      render: (_, record, index) => <Tag color="processing">{index + 1}</Tag>,
    },
    {
      title: "Document",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Actions",
      render: (_, record) => {
        return (
          <Space>
            <Button
              href={record.url}
              target="_blank"
              rel="noreferrer"
              icon={<DownloadOutlined />}
            >
              Preview / Download
            </Button>

            <Popconfirm
              title="Are you sure you want to remove this document? The action cannnot be undone once executed"
              onConfirm={() => {
                const payload: RemoveOtherDocument = {
                  school_id: school?.id!,
                  document_name: record.name,
                };
                handleRemoveDoc(payload);
              }}
            >
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                loading={isRemovingDoc}
              >
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const dataSource: OtherDocumentDownload[] = [];
  otherDocuments?.map((otherDocument) => {
    dataSource.push({
      name: otherDocument.name,
      url: otherDocument.url,
    });
  });

  return (
    <Modal
      title="View / Download Other Documents"
      centered
      open={open}
      onCancel={() => onOpenChange()}
      width={700}
      footer={[
        <Button type="default" key="cancel" onClick={() => onOpenChange()}>
          Close
        </Button>,
      ]}
    >
      <Row
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Col span={24}>
          <Table dataSource={dataSource} columns={columns} />
        </Col>
      </Row>
    </Modal>
  );
};
