import React, { useState } from "react";
import { Button, Modal, Row, Col, Form, DatePicker, Divider } from "antd";
import { Programme, Course } from "../../interfaces";
import RegistrationSettings from "./setup-steps/RegistrationSettings";
import Courses from "./setup-steps/Courses";
import { CourseRegistrationSetup } from "../../interfaces/CourseRegistrationSetup";
import { appSelector } from "../../helpers/appSelector";

interface CourseRegistrationSetupFormProps {
	onSubmit(values: CourseRegistrationSetup): void;
	toggleModal(): void;
	showTransferFormModal: boolean;
	programmes: Programme[];
	courses: Course[];
	form: any;
	isSubmitting: boolean;
}

export const CourseRegistrationSetupForm: React.FC<
	CourseRegistrationSetupFormProps
> = ({
	toggleModal,
	onSubmit,
	programmes,
	showTransferFormModal,
	courses,
	form,
	isSubmitting,
}) => {
	const [selectedCourses, setSelectedCourses] =
		useState<[React.Key, "string"]>();
	const schoolStore = appSelector((state) => state.schoolStore);

	const handleOnSubmit = (values) => {
		const combinedValues = {
			courses: selectedCourses,
			...values,
			school_id: schoolStore.school?.id,
		};

		onSubmit(combinedValues);
	};

	return (
		<Modal
			title="Setup Course Registration"
			maskClosable={false}
			centered
			visible={showTransferFormModal}
			onCancel={() => toggleModal()}
			width={700}
			footer={[
				<Button
					type="default"
					key="cancel"
					onClick={() => toggleModal()}
				>
					Close
				</Button>,
				<Button
					form="courseRegistrationSetupForm"
					key="submit"
					htmlType="submit"
					type="primary"
					loading={isSubmitting}
				>
					{isSubmitting ? "Saving.." : "Save"}
				</Button>,
			]}
		>
			<Row
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					marginTop: "10px",
				}}
			>
				<Col span={24}>
					<Form
						layout="vertical"
						name="basic"
						onFinish={(values) => handleOnSubmit(values)}
						id="courseRegistrationSetupForm"
						form={form}
						// initialValues={values}
					>
						<Col span={24}>
							<RegistrationSettings programmes={programmes} />
							<Row gutter={10}>
								<Col span={12} md={12} sm={24} xs={24}>
									<Form.Item
										name="start_date"
										label="Starting Date and Time"
										rules={[
											{
												required: true,
												message:
													"Starting Date and Time is required!",
											},
										]}
									>
										<DatePicker
											format="YYYY-MM-DD HH:mm:ss"
											use12Hours
											style={{ width: "100%" }}
											placeholder="Select Starting Date and Time"
											showTime
										/>
									</Form.Item>
								</Col>
								<Col span={12} md={12} sm={24} xs={24}>
									<Form.Item
										name="end_date"
										label="Ending Date and Time"
										rules={[
											{
												required: true,
												message:
													"Ending Date and Time is required!",
											},
										]}
									>
										<DatePicker
											format="YYYY-MM-DD HH:mm:ss"
											use12Hours
											style={{ width: "100%" }}
											placeholder="Select Ending Date and Time"
											showTime
										/>
									</Form.Item>
								</Col>
							</Row>
							<Divider />
							<Courses
								courses={courses}
								selectedCourses={selectedCourses}
								setSelectedCourses={setSelectedCourses}
							/>
						</Col>
					</Form>
				</Col>
			</Row>
		</Modal>
	);
};
