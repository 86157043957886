import { Row, message } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { RegistrationsList } from "../components/course-registration/RegistrationsList";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../helpers/appDispatch";
import { CourseRegistrationPayload } from "../interfaces/CourseRegistration";
import { appSelector } from "../helpers/appSelector";
import { getCourseRegistrations, getCourseRegistrationsFailure } from "../store/course-registration/actions";
import { constants } from "../helpers/constants";
import { messages } from "../helpers/messages";

const CourseRegistration = () => {
	const dispatch: AppDispatch = useDispatch();
	const { school } = appSelector((state) => state.schoolStore);
	const { courseRegistrations, isSubmitting, loading, success, failure, page, error } = appSelector(
		(state) => state.courseRegistrationStore
	);

	useEffect(() => {
		const payload: CourseRegistrationPayload = {
			school_id: school?.id,
			academic_year: school?.academic_year,
			semester: school?.semester,
		};
		dispatch(getCourseRegistrations(payload));
	}, []);

	if (failure && page == constants.courseRegistration) {
		message.error(messages.dataLoadFailure);
	}

	return (
		<>
			<React.Fragment>
				<Helmet>
					<title>Admissions Ghana | Course Registrations</title>
				</Helmet>
				<div className="padding-box">
					<Content className="site-layout-background site-content">
						<Row gutter={20}>
							<RegistrationsList courseRegistrations={courseRegistrations} loading={loading} />
						</Row>
					</Content>
				</div>
			</React.Fragment>
		</>
	);
};

export default CourseRegistration;
