import { action } from "typesafe-actions";
import { CourseRegistrationSetupTypes } from "./types";
import { CourseRegistrationSetup } from "../../interfaces/CourseRegistrationSetup";

export const createCourseRegistrationSetup = (payload: any) =>
	action(CourseRegistrationSetupTypes.CREATE_COURSE_REGISTRATION_SETUP, payload);

export const createCourseRegistrationSetupSuccess = (courseRegistrationSetup: CourseRegistrationSetup) =>
	action(CourseRegistrationSetupTypes.CREATE_COURSE_REGISTRATION_SETUP_SUCCESS, courseRegistrationSetup);

export const createCourseRegistrationSetupFailure = (error: any) =>
	action(CourseRegistrationSetupTypes.CREATE_COURSE_REGISTRATION_SETUP_FAILURE, error);

export const getAllCourseRegistrationSetups = (payload: any) =>
	action(CourseRegistrationSetupTypes.GET_ALL_COURSE_REGISTRATION_SETUPS, payload);

export const getAllCourseRegistrationSetupsSuccess = (courseRegistrationSetups: CourseRegistrationSetup[]) =>
	action(CourseRegistrationSetupTypes.GET_ALL_COURSE_REGISTRATION_SETUPS_SUCCESS, courseRegistrationSetups);

export const getAllCourseRegistrationSetupsFailure = (error: any) =>
	action(CourseRegistrationSetupTypes.GET_ALL_COURSE_REGISTRATION_SETUPS_FAILURE, error);

export const clearCourseRegistrationSetups = () => action(CourseRegistrationSetupTypes.CLEAR_DATA);

export const notifyStudentsRequest = (payload: any) => action(CourseRegistrationSetupTypes.NOTIFY_STUDENTS, payload);
export const notifyStudentsSuccess = (payload: any) =>
	action(CourseRegistrationSetupTypes.NOTIFY_STUDENTS_SUCCESS, payload);
export const notifyStudentsFailure = (payload: any) =>
	action(CourseRegistrationSetupTypes.NOTIFY_STUDENTS_FAILURE, payload);
