export const constants = {
	home: "home",
	login: "login",
	verify: "verify",
	register: "register",
	resend: "resend",
	reset: "reset",
	school: "school",
	programmes: "programmes",
	students: "students",
	letter: "letter",
	fees: "fees",
	admin: "admin",
	transfers: "transfers",
	clients: "clients",
	trainings: "trainings",
	profile: "Profile",
	changePassword: "Change Password",
	academics: "academics",
	courseRegistrationSetups: "course-registration-setup",
	courseRegistration: "course-registration",
};

export const regions = [
	{ label: "Ashanti", value: "Ashanti" },
	{ label: "Greater Accra", value: "Greater Accra" },
	{ label: "Northern", value: "Northern" },
	{ label: "Western", value: "Western" },
	{ label: "Volta", value: "Volta" },
	{ label: "Upper East", value: "Upper East" },
	{ label: "Central", value: "Central" },
	{ label: "Eastern", value: "Eastern" },
	{ label: "Upper West", value: "Upper West" },
	{ label: "Brong Ahafo", value: "Brong Ahafo" },
	{ label: "Bono East", value: "Bono East" },
	{ label: "Ahafo", value: "Ahafo" },
	{ label: "North East", value: "North East" },
	{ label: "Oti", value: "Oti" },
	{ label: "Savannah", value: "Savannah" },
	{ label: "Western North", value: "Western North" },
];

export const academicYears = [
	{ label: "2019-2020", value: "2019-2020" },
	{ label: "2020-2021", value: "2020-2021" },
	{ label: "2021-2022", value: "2021-2022" },
	{ label: "2022-2023", value: "2022-2023" },
	{ label: "2023-2024", value: "2023-2024" },
	{ label: "2024-2025", value: "2024-2025" },
	{ label: "2025-2026", value: "2025-2026" },
];

export const years = [
	{ label: "2021", value: "2021" },
	{ label: "2022", value: "2022" },
	{ label: "2023", value: "2023" },
	{ label: "2024", value: "2024" },
	{ label: "2025", value: "2025" },
	{ label: "2026", value: "2026" },
];

export const studentYear = [
	{ label: 100, value: 1 },
	{ label: 200, value: 2 },
	{ label: 300, value: 3 },
	{ label: 400, value: 4 },
];

export const semesters = [
	{ label: "Semester 1", value: 1 },
	{ label: "Semester 2", value: 2 },
];
