import React, { useEffect } from "react";
import CourseRegistrationsSetups from "../../pages/_courseRegistrationSetups";
import { appSelector } from "../../helpers/appSelector";
import { CourseRegistrationSetup } from "../../interfaces/CourseRegistrationSetup";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../helpers/appDispatch";
import { getAllCourseRegistrationSetups, notifyStudentsRequest } from "../../store/course-registration-setup";
import { Button, Col, Dropdown, Menu, MenuProps, Popconfirm, Row, Space, Table, Tag } from "antd";
import {
	DownOutlined,
	EditOutlined,
	EyeOutlined,
	PhoneFilled,
	PhoneOutlined,
	QuestionCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Content } from "antd/lib/layout/layout";
import { Helmet } from "react-helmet";
import { semesters, studentYear } from "../../helpers/constants";

export const CourseRegistrationSetupList = () => {
	const dispatch: AppDispatch = useDispatch();
	const schoolStore = appSelector((state) => state.schoolStore);
	const courseRegistrationStore = appSelector((state) => state.courseRegistrationSetupStore);
	const { school } = appSelector((state) => state.schoolStore);

	const { isSubmitting } = courseRegistrationStore;

	useEffect(() => {
		const payload = {
			school_id: schoolStore.school?.id,
		};
		dispatch(getAllCourseRegistrationSetups(payload));
	}, []);

	const onPopConfirm = (id: number) => {
		const payload = {
			school_id: school?.id,
			semester: school?.semester,
			academic_year: school?.academic_year,
			id: id,
		};
		dispatch(notifyStudentsRequest(payload));
	};

	const generateMenu = (id: number) => {
		return (
			<Menu>
				<Menu.Item key={1} icon={<EyeOutlined />}>
					View Courses
				</Menu.Item>
				<Popconfirm
					title="This will send an SMS to all students for the selected Programme . Are you sure you want to continue?"
					icon={<QuestionCircleOutlined />}
					onConfirm={() => onPopConfirm(id)}
					placement="bottom"
				>
					<Menu.Item key={2} icon={<PhoneOutlined />}>
						Notifty Students
					</Menu.Item>
				</Popconfirm>
				<Menu.Item key={3} icon={<EditOutlined />}>
					Edit
				</Menu.Item>
			</Menu>
		);
	};

	const columns: any[] = [
		{
			title: "Programme Name",
			dataIndex: "programme_name",
			align: "left",
			key: "programme_name",
			sorter: (a: any, b: any) => a.programme_name - b.programme_name,
		},
		{
			title: "Academic Year",
			dataIndex: "academic_year",
			align: "left",
			key: "academic_year",
			sorter: (a: any, b: any) => a.id - b.id,
		},
		{
			title: "Semester",
			dataIndex: "semester",
			align: "left",
			key: "semester",
			sorter: (a: any, b: any) => a.id - b.id,
			render: (semesterValue: any) => {
				return semesters.map((semester: any) => {
					return semesterValue == semester.value ? semester.label : "";
				});
			},
		},
		{
			title: "Level",
			dataIndex: "level",
			align: "left",
			key: "level",
			sorter: (a: any, b: any) => a.id - b.id,
			render: (levelValue: any) => {
				return studentYear.map((level: any) => {
					return levelValue == level.value ? level.label : "";
				});
			},
		},
		{
			title: "Courses Count",
			dataIndex: "course_registration_courses_count",
			align: "right",
			key: "course_registration_courses_count",
			sorter: (a: any, b: any) => a.id - b.id,
		},
		{
			title: "Minimum Credit Hours",
			dataIndex: "minimum_credit_hours",
			align: "right",
			key: "minimum_credit_hours",
			sorter: (a: any, b: any) => a.id - b.id,
			render: (minimum_credit_hours: number) => <Tag color="green">{minimum_credit_hours}</Tag>,
		},
		{
			title: "Start Date",
			dataIndex: "start_date",
			align: "left",
			key: "start_date",
			render: (start_date: string) =>
				start_date != undefined ? (
					<Tag color="green">{moment(start_date).format("YYYY-MM-DD HH:mm:ss")}</Tag>
				) : (
					<Tag color="yellow">Not Available</Tag>
				),
		},
		{
			title: "End Date",
			dataIndex: "end_date",
			align: "left",
			key: "end_date",
			render: (end_date: string) =>
				end_date != undefined ? (
					<Tag color="red">{moment(end_date).format("YYYY-MM-DD HH:mm:ss")}</Tag>
				) : (
					<Tag color="yellow">Not Available</Tag>
				),
		},
		{
			title: "Action",
			dataIndex: "",
			key: "x",
			render: (courseRegistrationSetup: CourseRegistrationSetup) => {
				return (
					<Space>
						<Dropdown overlay={generateMenu(courseRegistrationSetup.id)}>
							<Button type="dashed" icon={<DownOutlined />} onClick={() => {}}>
								Actions
							</Button>
						</Dropdown>
					</Space>
				);
			},
		},
	];

	let setupsData: CourseRegistrationSetup[] = [];

	courseRegistrationStore.courseRegistrationSetups.map((setup) => {
		setupsData.push({
			id: setup.id,
			key: setup.id,
			programme_name: setup.programme?.programme,
			school_id: setup.school_id,
			course_registration_courses_count: setup.course_registration_courses_count,
			created_at: moment(setup.created_at, "YYYY-MM-DD HH:mm:ss").format("MMMM D, YYYY"),
			updated_at: moment(setup.updated_at, "YYYY-MM-DD HH:mm:ss").format("MMMM D, YYYY"),
			academic_year: setup.academic_year,
			course_registration_courses: setup.course_registration_courses,
			level: setup.level,
			programme_id: setup.programme_id,
			semester: setup.semester,
			start_date: setup.start_date,
			end_date: setup.end_date,
			minimum_credit_hours: setup.minimum_credit_hours,
		});
	});

	return (
		<>
			<React.Fragment>
				<Helmet>
					<title>Admissions Ghana | Course Registration Setup</title>
				</Helmet>
				<div className="padding-box">
					<Content className="site-layout-background site-content">
						<div className="margin-top">
							<CourseRegistrationsSetups />
							<Row gutter={20}>
								<Col span={24}>
									<div>
										<Table
											loading={isSubmitting}
											dataSource={setupsData}
											columns={columns}
											bordered
											pagination={{
												hideOnSinglePage: true,
												total: setupsData.length,
												showTotal: (total, range) => {
													return `Showing ${range[0]} - ${range[1]} of ${total} results`;
												},
											}}
											className="students-table"
											scroll={{ x: true }}
										/>
									</div>
								</Col>
							</Row>
						</div>
					</Content>
				</div>
			</React.Fragment>
		</>
	);
};
